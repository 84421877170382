

import HeaderNav from './components/HomePage/Header';
import Home from './components/Home';
import Footer from './components/HomePage/Footer';

import AOS from 'aos';
import $ from "jquery";
import React, { useEffect } from 'react';
import './App.scss';
import './components/scss/varibles.scss'
import Rooting from './Router';


const refreshPage = ()=>{
 // window.location.reload();
}



class App extends React.Component {
  componentDidMount() {

    AOS.init();
    AOS.init({disable: 'mobile'});
  }



  render() {
    return (

      <div className="App">

        <HeaderNav />
        <Rooting   />
        <Footer />





      </div>
    );
  }

}

export default App;
