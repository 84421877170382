import React from 'react'


 const ArticleFeature = ({title ,bodyContent , bodyImg, bodyContent1, bodyContent2, bodyContent3}) => {
    return (
       

            <div class="ap-section-content">
                <div class="overview-content">
                    <h2 class="overview-title">{title}</h2>
                    <p class="pb-15"> {bodyContent} </p>
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="overview-thumb mb-30">
                      
                                <img src={bodyImg} />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="ap-content-inner d-flex">
                                <div class="ap-content-icon">
                                    <i class="bi bi-check-circle"></i>
                                </div>


                                <div class="ap-content-text ml-20">
                                    {bodyContent1}
                                </div>

                            </div>
                            <div class="ap-content-inner d-flex mt-20">
                                <div class="ap-content-icon">
                                    <i class="bi bi-check-circle"></i>
                                </div>


                                <div class="ap-content-text ml-20">
                                {bodyContent2}
                                  </div>

                            </div>

                        </div>
                    </div>
                    <p class="pt-15 pb-25">
                    {bodyContent3}
                    </p>
                </div>
            </div>
     
    )
}

export default ArticleFeature;