import React from "react";
import '../scss/Portfolio.scss';
import { Routes, Route, Outlet, Link, NavLink } from "react-router-dom";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

const Portfolio = (refreshPage) => {
    return (
        <section id="work">

            <section className="val-portfolio ">
                <div className="val-portfolio_body">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-4">
                                <h5 className="primary-white" data-aos="flip-up"> Our recent works</h5>
                            </div>
                            <div className="col-md-8">
                                <h3 data-aos="fade-up"> We do what we do best. We don't do what we're not good at <span className="primary-orange"> Simple...</span></h3>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-10 mx-auto val-portfolio_body_card">
                                <div className="row">
                                    <div className="col-6 val-portfolio_body_card_item">
                                        <Link  onClick={scrollToTop} to="/services/business-solutions"   >
                                            <img src={process.env.PUBLIC_URL + '/images/portfolio-1.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                            <h4> End-to-End Business Services</h4>
                                        </Link>
                                    </div>
                                    <div className="col-6 val-portfolio_body_card_item">
                                        <Link onClick={scrollToTop} to="/services/technology-consulting"   >
                                            <img src={process.env.PUBLIC_URL + '/images/portfolio-2.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                            <h4> IT Platform Services</h4>
                                        </Link>
                                    </div>
                                </div>
                                <div className=" row mt-5">
                                    <div className="col-6 val-portfolio_body_card_item">
                                    <Link onClick={scrollToTop} to="/services/staffing-augmentation"   >
                                        <img src={process.env.PUBLIC_URL + '/images/portfolio-3.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                        <h4> Staffing Augmentation</h4>
                                        </Link>
                                    </div>
                                    <div className="col-6 val-portfolio_body_card_item">
                                    <Link  onClick={scrollToTop}to="/digital-tech/business-intelligence"   >
                                        <img src={process.env.PUBLIC_URL + '/images/portfolio-4.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                        <h4> Business Intelligence and Analytics</h4>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="val-feature  bg-white">

            <div class="container">
			<div class="row footer-hr">
				<div class="col-md-6">
					<div class="subscribe-section-content d-flex">
						<div class="subscribe-section-icon">
                        <i class="bi bi-globe"></i>
						</div>
						<div class="subscribe-section-title">
							<h5>Sign up for latest updates</h5>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="subscribe-input-field">
						<form action="#">
							<div class="subscribe-mail-box"> 
								<input class="subscribe-mail-box" type="email" placeholder="Enter Your Email..." required/>
								<button class="subscribe-submit-btn" type="submit" name="submit">Subscribe</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

            </section>
        </section>
    );
}

export default Portfolio;