import React from 'react'

import { aidArticle } from '../Data/ArticleData';


 const Article = ({heading ,bodyContent, articleDataimg }) => {
    return (
        <section>
            <div class="section-thumb">
            
                <img src={articleDataimg} />
            </div>
            <div class="overview-content">
                <h1 class="overview-title">{heading}</h1>
                <p> {bodyContent}</p>
            </div>
        </section>
    )
}

export default Article;