import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import {M365BD } from '../Data/BreadcrumbData'
import {M365AD } from '../Data/ArticleData'
import {M365Article } from '../Data/ArticleFeatureData';
import {M365Title } from '../Data/LeftColmnsData'

const M365Page = () => {


    return (
        <>
            <div>
            
            <Breadcrumb  {...M365BD}/>
          
               
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                            <LeftColumn{...M365Title} />

                            </div>
                            <div class="col-lg-8">
                              <Article {...M365AD} />
                              
                              <div class="ap-section-content">
                                    <div class="overview-content">
                                        <h2 class="overview-title"> Our Microsoft 365 Services:</h2>
                                        <div class="row">
                                        <div class="col-md-12"> 
                                        <ul class="list-style-bullet">
                                            <li>Microsoft 365 Setup & Migration: Transition to Microsoft 365 with ease! Our experts handle everything from setup to migration, ensuring a smooth, hassle-free switch with zero downtime. </li>
                                            <li>
                                            Customization & Integration: Tailor Microsoft 365 to fit your business needs by integrating it with your existing tools and workflows. We customize everything from SharePoint to Teams, so your team gets the most out of the platform. 
                                            </li>

                                            <li>
                                            Security & Compliance Solutions: Protect your data with Microsoft 365’s robust security features. We help you configure policies, ensure compliance with industry standards, and keep your sensitive information safe.
                                            </li>

                                            <li>
                                            Training & Support: Empower your team with expert training and ongoing support. We provide hands-on sessions to get your staff up to speed with Microsoft 365’s full range of features, maximizing productivity.
                                            </li>
                                            <li>
                                            Managed Microsoft 365 Services: Let us manage your Microsoft 365 environment for you. From regular updates to user management and troubleshooting, our team ensures everything runs smoothly while you focus on your business.
                                            </li>
                                        </ul>
                                        </div>
                                        </div>
                                          <div class="row">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="overview-thumb mb-30">
                                                    <img src="/images/innerpage/ai-2.png"></img>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div class="ap-content-inner d-flex">
                                                    <div class="ap-content-icon">
                                                        <i class="bi bi-check-circle"></i>
                                                    </div>
                                                <div class="ap-content-text ml-20"> Why Choose Valuesoft Info?</div>
                                            </div>
                                            <div class="ap-content-inner d-flex mt-20">
                                                <div class="ap-content-icon">
                                                    <i class="bi bi-check-circle"></i>
                                                </div>
                                                <div class="row">
                                        <div class="col-md-12"> 
                                        <ul class="list-style-bullet">
                                            <li>	Seamless Implementation: Experience a smooth transition with zero disruption to your daily operations.</li>
                                            <li>
                                            Expert Guidance: Leverage our in-depth Microsoft 365 expertise to customize and optimize the platform for your business.
                                            </li>

                                            <li>
                                            Ongoing Support: Our dedicated team provides continuous support, ensuring your Microsoft 365 environment is always up and running.
                                            </li>

                                            <li>
                                            Increased Collaboration & Productivity: Get the most out of Microsoft’s tools for communication, collaboration, and document management.
                                            </li>

                                            
                                        </ul>
                                        </div>
                                        </div>
                                                </div></div></div><p class="pt-15 pb-25">Supercharge your workplace with Valuesoft Info’s Microsoft 365 Services—where productivity meets simplicity!</p></div></div>

                              
                              
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
}

export default M365Page;