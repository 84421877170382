

export const BusinessAD = {
    "id": 1,
    "heading": "Business Strategy & Solutions",
    "bodyContent": "A well-designed business and technology strategy is the foundation for digital transformation success. Business leaders continue to pressure IT to deliver innovation while doing “more with less”. Customers and employees are becoming increasingly technology savvy and are expecting corporate technology to keep pace with consumer technology developments.",
    "articleDataimg": "/images/innerpage/business-1.jpg"
};
export const itConsultingAD = {
    "id": 1,
    "heading": "IT Technology Consulting ",
    "bodyContent": "Whether your IT staffing requirements are permanent, temporary or contract, Valuesoft Info is dedicated to helping you determine the most effective solution for your information technology investment. We represent several highly motivated and qualified technical professionals available for permanent, contract or temporary placement services over the full scope of emergent IT fields. As a specialist provider, we understand the critical impact that IT has on your business.",
    "articleDataimg": "/images/innerpage/consulting-1.jpg"
};
export const HrSolutionsAD = {
    "id": 1,
    "heading": "The outstanding human resources consulting and outsourcing firm with the resources",
    "bodyContent": " expertise, and global reach to solve the most pressing and complex people challenges that organizations face today. We employ the strongest team of professionals in the industry, who have access to the widest breadth and depth of research, platforms, and services in more locations and for more industry segments than any other human capital firm. Our singular focus is on our clients: We deliver distinctive value, top-rated customer service, and measurable business impact.",
    "articleDataimg": "/images/innerpage/hrbp(1).jpg"
};

export const ProcurementAD = {
    "id": 1,
    "heading": "Globalization has  changed the game for businesses.",
    "bodyContent": " Today, it’s possible to widen the reach and push the scale across geographies – creating an expansive operational blueprint. Therefore, firms need smarter and more focused global supply chain models. Automated Supply Chain Models have fast gained traction, offering a competitive edge, smarter economic flowcharts and contemporaneous products. However, SCM is a complex terrain and global firms have to seamlessly manage an ever-expanding network of stakeholders.",
    "articleDataimg": "/images/innerpage/analytics(2).jpg"
};
export const CorporateTrainingAD = {
    "id": 1,
    "heading": "  Corporate Training and Instructor-Led Training ",
    "bodyContent": " Valuesoft Info IT Training and instructor-led training expertise to deliver and guide course learning through structure, process and support. Our highly trained IT facilitators and certified professionals assist leaders and their organizations in providing intensive and more focused classes and workshops to enhance the skills and knowledge of their workforce  ",
    "articleDataimg": "/images/innerpage/cpt(1).jpg"
};

export const StaffingAugmentationAD = {
    "id": 1,
    "heading": " Empower Your Team with Valuesoft Info’s Staff Augmentation Services ",
    "bodyContent": " At Valuesoft Info, we offer a comprehensive range of staff augmentation services designed to meet the evolving needs of businesses. Whether you’re looking to fill short-term gaps or need specialized expertise for long-term projects, our solutions ensure you have the right talent, when and where you need it.  ",
    "articleDataimg": "/images/innerpage/Staff_Aug_1.jpg"
};

export const OutsourcingAD = {
    "id": "1    ",
    "heading": " Transform Your Business with VSI’s Outsourcing Services   ",
    "bodyContent": "At Valuesoft Info, we offer end-to-end outsourcing solutions that allow you to focus on what you do best - growing your business. Let us handle the heavy lifting with our range of flexible, scalable outsourcing services designed to optimize your operations.   ",
    "articleDataimg": "/images/innerpage/outsourcing-1.jpg"
};

export const BusinessIntelligenceAD = {
    "id": 1,
    "heading": "Business Intelligence Solutions and Services   ",
    "bodyContent": " As Business Intelligence (BI) software developers continue to advance the technology’s ability to automate mission-critical decisions, enterprises that fail to invest will find themselves unable to catch up to their more modern competitors. We expertly program and implement BI solutions for market forecasting, trend analysis and operations optimization. We develop Digital Asset Management (DAM) systems with your specific business needs in mind, whether you need one for production management, maintaining archives, managing your brand or providing your sales team with the assets it needs.  ",
    "articleDataimg": "/images/innerpage/analytics-2.jpg"
};

export const DAManagmentAD = {
    "id": 1,
    "heading": "Let Value Soft Create Your DAM Software   ",
    "bodyContent": " CSR is one of the fundamental principles of Valuesoft. We participate in social projects that leverage data science and software development for good causes. We develop Digital Asset Management (DAM) systems with your specific business needs in mind, whether you need one for production management, maintaining archives, managing your brand or providing your sales team with the assets it needs. If your organization exclusively handles rich media assets (videos, audio files, images, e-books), we can also create a more specialized, customer-centric Media Asset Management (MAM) platform.  ",
    "articleDataimg": "/images/innerpage/dam-1.jpg"
};

export const ArVrAD = {
    "id": 1,
    "heading": " Augmented Reality (AR) and Virtual Reality (VR)    ",
    "bodyContent": "  Valuesoft Info is a team of experienced designers and developers fully committed to creating the virtual as well as the intelligent identity for the business and service of their clients. The team of experts  in the virtual  reality app development center at Valuesoft Info is highly capable of developing VR applications with the modern framework that is efficiently built for the major devices that include Samsung VR, Google Cardboard, Oculus Rift VR, Microsoft Hololens and many others. VR app development is highly suitable for more number of business functions like marketing, product demos, sales presentations and more. ",
    "articleDataimg": "/images/innerpage/ar-2.jpg"
};

export const M365AD = {
    "id": 1,
    "heading": " Maximize Productivity with Valuesoft Info’s Microsoft 365 Services    ",
    "bodyContent": " Unlock the full potential of Microsoft 365 with Valuesoft Info! We offer comprehensive services that help businesses harness the power of Microsoft’s productivity tools, enabling seamless collaboration, secure communication, and enhanced efficiency across your organization. ",
    "articleDataimg": "/images/innerpage/ai-1.jpg"
};
