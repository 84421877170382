
import React, { useState, useEffect, useRef, useMenuState } from 'react';
import { Routes, Route, Outlet, Link, NavLink } from "react-router-dom";
import '../scss/header.scss';

import '../scss/varibles.scss';

import Company from '../InnerPage/Company';
import Services from '../InnerPage/Services';
import M365 from '../InnerPage/M365';
import Home from '../Home';
import Rooting from '../../Router';
import App from '../../App';
import {
  Menu,
  MenuItem,
  ControlledMenu,
  MenuButton
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';


const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
};

const HeaderNav = (refreshPage) => {

  const [scroll, setScroll] = useState(0)
  const [hovered, setHovered] = useState(0);
  const toggleHover = () => setHovered(!hovered);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 50
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    })
  })



  return (

    <div id="val-header" className={scroll ? "sticky_nav" : "regular_nav"}>

      <nav className="navbar navbar-light p-0">
        <div className="container-fluid px-0">
          <NavLink  to="/"     className="val-header_left" >
            <img src={process.env.PUBLIC_URL + '/images/VS-LOGO.png'} alt="ValueSoft Logo" />
          </NavLink>
          <ul className="val-header_center">
            <li>  <NavLink exact={true} onClick={scrollToTop}    activeClassName='is-active' to="/" >Home</NavLink></li>

            <li >

              <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/our-company" >About Us</NavLink>
             
       


            </li>

            <li className="mega-dropdown">

              <NavLink exact={true} onClick={scrollToTop}  activeClassName='is-active' to="/services">  Consulting Services 
               <i  className={hovered ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}            
                >
              </i> </NavLink>
              <ul className="mega-dropdown__body">
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/services/business-solutions"  > Business Strategy & Solutions
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}    activeClassName='is-active' to="/services/technology-consulting"   >  IT Technology Consulting 
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}    activeClassName='is-active' to="/services/hr-services"  >  Human Resources Services
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/services/procurement-supplychain"   >  Procurement & Supply chain
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/services/corporate-training" > Corporate Training
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/services/staffing-augmentation" > Staffing Augmentation
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/services/outsourcing"   > Outsourcing
                  </NavLink>

                </li>

              </ul>

            </li>

            <li className="mega-dropdown">

              <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/services" > Digital Tech 
              <i  className={hovered ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}            
                >
              </i>
               </NavLink>
              <ul className="mega-dropdown__body">
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/digital-tech/business-intelligence" >Business Intelligence
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}   activeClassName='is-active' to="/digital-tech/digital-asset-managment"   > Digital Asset Management
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true} onClick={scrollToTop}  activeClassName='is-active' to="/digital-tech/ar-vr"  > AR / VR
                  </NavLink>

                </li>
                <li>
                  <NavLink exact={true}  onClick={scrollToTop}  activeClassName='is-active' to="/digital-tech/Microsoft-365"  >  Microsoft 365
                  </NavLink>

                </li>


              </ul>

            </li>

            <li>
            <NavLink exact={true} onClick={scrollToTop} activeClassName='is-active' to="/contact-us"  > Contact Us
                  </NavLink>
             </li>
          </ul>

          <div className="val-header_right">
            
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span className="navbar-toggler-icon">
                <img src={process.env.PUBLIC_URL + '/images/toggle.svg'} alt="ValueSoft_toggle " />
              </span>
            </button>
          </div>
        
          <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">    <img src={process.env.PUBLIC_URL + '/images/VS-logo.png'} className="img-fluid w-50" alt="Value Soft Logo" /> </h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">  <img className="img-fluid w-50" src={process.env.PUBLIC_URL + '/images/close.png'} alt="ValueSoft Logo" /></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                <li className="nav-item">
                  <NavLink onClick={scrollToTop}  className="nav-link active" aria-current="page" to="/our-company">About Us <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={scrollToTop} className="nav-link" to="/services">Services <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={scrollToTop} className="nav-link" to="/digital-tech/Microsoft-365">Microsoft 365 <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={scrollToTop}  className="nav-link" to="/services/technology-consulting">Consulting Services   </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={scrollToTop} className="nav-link" to="/digital-tech/business-intelligence">Digital Tech  <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={scrollToTop} className="nav-link" to="/">Careers<img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={scrollToTop} className='nav-link' to="/contact-us">Contact Us  <img src={process.env.PUBLIC_URL + '/images/right-orange.svg'} alt="ValueSoft Logo" /> </NavLink>
                </li>
              </ul>
              <div class="cda-content text-left">
                    			<ul class="social">
                        			<li class="active" ><a href="https://www.facebook.com/valuesoftinfo/"  target="_blank"><i class="bi bi-facebook"></i></a></li>
                        			<li><a href="https://twitter.com/ValueSoft_info"  target="_blank"><i class="bi bi-twitter"></i></a></li>
                       			
                        			<li><a href="https://www.instagram.com/valuesoftinfo"  target="_blank"><i class="bi bi-instagram"></i></a></li>
									<li><a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank"><i class="bi bi-linkedin"></i></a></li>
                    			</ul>
              </div>

            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </div>

  );
};

export default HeaderNav;

